import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Webhooks without headaches</SectionTitle>
      <FeaturesGrid>
        {/* <FeatureItem>
          <FeatureTitle>High availability of webhooks</FeatureTitle>
          <FeatureText>
            Our single-purpose service is exposes webhook endpoints with extremely high availability.
          </FeatureText>
        </FeatureItem> */}
        <FeatureItem>
          <FeatureTitle>Automatic webhook retries</FeatureTitle>
          <FeatureText>
            If ever your service is down, you won't miss out on webhooks because we provide configurable retries.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Fan-out webhooks</FeatureTitle>
          <FeatureText>
            Do you have multiple services that need to receive webhooks? We fan-out of inbound webhooks to all your services.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Filter inbound webhooks</FeatureTitle>
          <FeatureText>
            Set up filtering rules for a given endpoint. Filter out spam requests or ensure only authenticated requests are propagated.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Inject headers in outbound webhooks</FeatureTitle>
          <FeatureText>
            Injecting custom headers is useful if you want to do authentication on your services' webhook endpoints.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Telemetry and alerts</FeatureTitle>
          <FeatureText>
            Monitor incoming and outgoing requests and response and their status codes. Receive notifications if your service return errors.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Debugging made easy</FeatureTitle>
          <FeatureText>
            Tail events to your console. Replay events to specific environments. Everything to make the developer life easier.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`
